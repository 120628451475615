<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>회원관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>기본 정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사</label>
            <ul>
              <li>{{userInfo.cmpyNm}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>성명</label>
            <ul>
              <li>{{userInfo.userNm}}</li>
            </ul>
          </dd>
          <dd><label>권한</label>
            <ul>
              <li>{{userInfo.authNm}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>ID</label>
            <ul>
              <li>{{userInfo.userId}}</li>
            </ul>
          </dd>
          <dd><label>비밀번호</label>
            <ul>
              <li>
                <button @click="resetPW" class="mid_btn">비밀번호 초기화</button>
                <span class="forg"> **영문, 숫자, 특수문자 포함된 8자리 이상 난수</span>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>휴대전화번호</label>
            <ul class="file_list">
              <li>{{userInfo.spTelNo}}</li>
            </ul>
          </dd>
          <dd><label>유선번호</label>
            <ul>
              <li>{{userInfo.workTelNo }}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>이메일주소</label>
            <ul>
              <li>{{userInfo.email}}</li>
            </ul>
          </dd>
          <dd><label>직위</label>
            <ul>
              <li> {{ userInfo.psnScnNm }}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>계정상태</label>
            <ul>
              <li>{{userInfo.activeYn === 'Y' ? '활성화' : '비활성화'}}</li>
            </ul>
        </dd>
          <dd><label>비고</label>
            <ul>
              <li>{{userInfo.contents}}</li>
            </ul>
          </dd>
        </li>
        <li v-if="userInfo.sleeperYn === 'Y'">
          <dd><label>휴면상태</label>
            <ul>
              <li><button @click="wakeupSleeper" class="mid_btn">해제</button></li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>접속 가능 회사 정보</h2>
      </div>
      <kendo-grid
          ref="userMngGridRef"
          style="cursor: pointer; max-height: 360px"
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :data-source="mngList"
          :columns="mngGridColumns"
          :selectable="'row'"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
      </kendo-grid>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>개인 권한 정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>이용약관 및 개인정보 활용 동의 일시</label>
            <ul>
              <li>{{ userInfo.psiAgrDt ? '동의' : '미동의' }}
                <span class="mgl10">{{ userInfo.psiAgrDt }}</span>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>마케팅 동의 일시</label>
            <ul>
              <li>
                e-mail : {{ userInfo.mktEmailAgrDt ? '동의' : '미동의' }}
                <span class="mgl10">{{ userInfo.mktEmailAgrDt }}</span> <br>
                SMS : {{ userInfo.mktSmsAgrDt ? '동의' : '미동의' }}
                <span class="mgl10">{{ userInfo.mktSmsAgrDt }}</span> <br>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>최근 로그인 정보</label>
            <ul>
              <li>{{ userInfo.lastLoginDt ? userInfo.lastLoginDt : '없음' }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.push({ name: 'UserManageList' })">목록</button>
        </li>
        <li v-if="userInfo.authCd !== Constant.efs.auth.SYSTEM_ADMINISTRATOR" class="w30">
          <button class="large_btn dgray"
                  @click="onClickDeleteUser">삭제</button>
        </li>
        <li v-if="userInfo.authCd !== Constant.efs.auth.SYSTEM_ADMINISTRATOR" class="w30">
          <button class="large_btn orange"
                  @click="$router.push({name: 'UserManageEdit' })">수정</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import Constant from '@/common/constant'

export default {
  name   : 'UserManageDetail',
  created () {
    const vm = this
    vm.getUserManageDetail()
  },
  computed : {
    corporateImg : function() {
      if(this.userInfo?.corporateFile?.length > 0){
        return this.userInfo?.corporateFile[0]?.orgFileNm
      }else return null

    }
  },
  methods : {
    resetPW : function (){
      const vm = this
      kendo.confirm("비밀번호를 초기화 하시겠습니까?").done(function (){
        ApiUtil.patch(`${ApiConfig.efsDomain}/efs/manage/user/reset-password/${vm.$route.params.unqUserId}`)
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                kendo.alert("비밀번호 초기화가 완료되었습니다.<br>초기화된 비밀번호는 메일로 발송되었습니다.")
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      })
    },
    wakeupSleeper : function (){
      const vm = this
      kendo.confirm("휴면상태를 해제 하시겠습니까?").done(function (){
        ApiUtil.patch(`${ApiConfig.efsDomain}/efs/manage/user/cancel-dormant/${vm.$route.params.unqUserId}`)
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                vm.getUserManageDetail()
                kendo.alert("휴면상태가 해제 되었습니다.")
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      })
    },
    getUserManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/user/${vm.$route.params.unqUserId}`)
      .then(function (response) {
          if (response.data.resultStatus.messageCode === '2000') {
            vm.userInfo = response.data.resultData
            if(response.data.resultData.mngList?.length > 0){
              vm.mngList = EtnersCommonUtil.setRowNumber(response.data.resultData.mngList)
            }
          } else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        },
      )
    },
    onClickDeleteUser : function() {
      const vm = this
      kendo.confirm('회원를 삭제하시겠습니까?').done(function (){
        vm.deleteUser()
      })
    },
    deleteUser : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/user/${vm.$route.params.unqUserId}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'UserManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
    data   : function () {
      const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
      const centerAttributes = { style: 'text-align: center;' }
      return {
        ApiConfig,
        Constant,
        userInfo  : {},
        mngList : [],
        mngGridColumns : [
          {
            field: 'rowNumber',
            title: 'No',
            width: '3%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'cmpyNm',
            title: '회사명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'cmpyCd',
            title: '회사코드',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'locaNm',
            title: '기본로케이션',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'cmpyGbnNm',
            title: '회사구분',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'corporateRegNo',
            title: '사업자번호',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
        ],
      }
    },
  }
</script>
